import React, { FC, ReactNode } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    SxProps,
    Box,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
interface AlertDialogProps {
    title?: string | ReactNode;
    description?: string | ReactNode;
    confirmButton?: ReactNode;
    abortButton?: ReactNode;
    component?: any
    onClose?: () => void;
    open: boolean;
    sx?: SxProps;
    selected?: string;
    dialogWidth?: string;
}

const AlertDialog: FC<AlertDialogProps> = (props) => {
    const theme = useTheme();
    const {
        title,
        description,
        confirmButton,
        abortButton,
        open,
        onClose,
        sx,
        component,
        selected,
        dialogWidth
    } = props;
    
    return (
      <Dialog
        sx={{ zIndex: theme?.custom?.dialogZIndex, ...sx }}
        open={open}
        onClose={onClose}
      >
        <Box sx={{ width: dialogWidth ?? "unset" }}>
          {title && <DialogTitle>{title}</DialogTitle>}
          {description && <DialogContent>{description}</DialogContent>}
          {selected && selected === "reTranscribe" && component
            ? component
            : null}
          {confirmButton || abortButton ? (
            <DialogActions>
              {abortButton}
              {confirmButton}
            </DialogActions>
          ) : null}
        </Box>
      </Dialog>
    );
};

export default AlertDialog;
