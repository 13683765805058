import { atom } from 'jotai';

interface categoriesStateInterface {
    categoryData: any[];
}

export const INITIAL_categories_ATOM_VALUE: categoriesStateInterface = {
    categoryData: []
}

export const categoryAtom = atom(INITIAL_categories_ATOM_VALUE);