import { ReactKeycloakProvider } from "@react-keycloak/web";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { useState } from "react";
import keycloak from "../../../keycloak";
import { baseAtom } from "../../../stores/jotai/base";
import Keycloak from '../../app/keycloak';

const tokenLogger = (tokens: unknown) => {
    //
}

export const MAX_LOADING_STATES = 4;

// key, anchor, open, setIsOpen are required
function GenericPage(props) {
    const [base, setBase] = useAtom(baseAtom);
    const [authenticated, setAuthenticated] = useState(false);
    const [authenticationState, setAuthenticationState] = useState(0);
    const [loadingStatus, setLoadingStatus] = useState('Getting your account information...');
    const router = useRouter();

    const eventLogger = (event: unknown, error: unknown) => {
        console.log(`keycloak event (${JSON.stringify(event)})`);

        if (JSON.stringify(event) === `"onInitError"`) {
            router.push("/maintenance-error");
        }
    };

    return <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={eventLogger}
        onTokens={tokenLogger}
    >
        <Keycloak
            org={base?.org}
            user={base?.user}
            cohort={base?.cohort}
            pageRequest={base?.pageRequest}
            loginStatusUpdate={(result) => {
                try {
                    setAuthenticationState(result?.loadingState);
                    setLoadingStatus(result?.loadingStatus);
                }
                catch (e) {

                }
            }}
            baseUpdate={(payload) => {
                try {
                    if (payload?.org?.orgID?.length > 0) {
                        setAuthenticated(true);
                    }
                }
                catch (e) { }
            }}
            team={base?.team} appState={base?.app} children={props.children} />
    </ReactKeycloakProvider>
}

export default GenericPage;
